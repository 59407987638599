import React, { useEffect, useRef, useState } from 'react';
import styles from "./InterfaceStructure.module.css";
import { clearUserInfo, getTenants, logout } from '../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import imgLogo from "../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png";
import imgProfile from "../assets/profile/user.png";
import { IoMenu } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import apiAxios from '../api/apiAxios';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const InterfaceStructure = ({ itemsPages, dashboardContent }) => {
    const [tenants, setTenants] = useState([]);
    const [user, setUser] = useState();
    const [isRightHeaderVisible, setIsRightHeaderVisible] = useState(window.innerWidth >= 700 ? true : false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const userInfo = useSelector(state => state.user.userInfo);
    const navigate = useNavigate();
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const dispatch = useDispatch();
    const profileMenuRef = useRef(null);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setIsProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleRightHeaderVisibility = () => {
        setIsRightHeaderVisible(!isRightHeaderVisible);
    };

    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    const handleLogout = () => {
        dispatch(logout());
        clearUserInfo();
    };

    return (
        <div className={styles.all}>
            {isRightHeaderVisible ? (
                <div className={`${styles.rightHeader} ${isRightHeaderVisible ? styles.open : styles.closed}`}>
                    <div className={styles.logo}>
                        <img src={imgLogo} alt='logo' />
                        <div className={styles.menuButtom} onClick={toggleRightHeaderVisibility}><IoMenu /></div>
                    </div>
                    <div className={styles.itemsContent}>
                        {itemsPages.map((item) => (
                            <div onClick={() => navigate(item.link)} id={item.id} className={styles.itemElement} style={item.isSelect ? { background: "rgba(255, 255, 255, 0.295)" } : null}>
                                <p className={styles.icon}>{item.icon}</p><p className={styles.text}>{item.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                window.innerWidth >= 700 ?
                    <div className={styles.menuButtomRightHeader} onClick={toggleRightHeaderVisibility}>
                        <IoMenu />
                    </div>
                    :
                    <div className={styles.menuButtomRightHeader} onClick={toggleRightHeaderVisibility}>
                        <IoMenu />
                    </div>
            )}
            <div className={styles.screen} style={isRightHeaderVisible ? null : { width: "100%" }}>
                <header>
                    <div className={styles.rightSide}>
                        <div className={styles.logo}>
                            {tenants.map((tenant) => (
                                <img key={tenant._id} src={tenant.imageUrl} alt='logo' onClick={()=>navigate('/')} />
                            ))}
                        </div>
                    </div>
                    <div className={styles.leftSide}>
                        {user && (
                            <div className={styles.profile} onClick={toggleProfileMenu} ref={profileMenuRef}>
                                <img src={user.img ? user.img : imgProfile} alt='profile' />
                                <p>{user.firstName}</p>
                                <p className={styles.arrowProfile}>{isProfileMenuOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</p>
                                {isProfileMenuOpen && (
                                    <div className={styles.profileMenu}>
                                        <Link to={`/perfil/${tokenPayload._id}`}>Perfil</Link>
                                        <Link to={'/'} onClick={handleLogout}>Sair</Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </header>
                <div className={styles.contentScrennFull}>
                    <div className={styles.contentScrennDash}>
                        {dashboardContent}
                    </div>
                </div>
                {window.innerWidth >= 700 ?
                    <></>
                    :
                    <div style={{width:"100%",height:"45px",background:"#ec3237"}}>
                        
                    </div>
                }
            </div>
        </div>
    )
}

export default InterfaceStructure;
