import React, { useState } from 'react';
import apiAxios from '../../../api/apiAxios';
import styles from './EditUserPassawordComponent.module.css';
import Message from '../tools/Message';
import { FaRegSave } from 'react-icons/fa';

const EditUserPasswordComponent = ({ userId }) => {
    console.log(userId)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setMessageType('');    
    
        // Validação básica: mínimo de 6 caracteres
        const validatePassword = (password) => {
            return password.length >= 4;
        };
    
        if (!password || !confirmPassword) {
            setMessage('Por favor, preencha todos os campos.');
            setMessageType('error');
            return;
        }
    
        if (password !== confirmPassword) {
            setMessage('As senhas não coincidem.');
            setMessageType('error');
            return;
        }
    
        if (!validatePassword(password)) {
            setMessage('A senha deve ter pelo menos 6 caracteres.');
            setMessageType('error');
            return;
        }
    
        try {
            await apiAxios.put(`/api/user/user/${userId}/password`, { password });
            setMessage('Senha atualizada com sucesso!');
            setMessageType('success');
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            setMessage('Erro ao atualizar a senha: ' + (error.response?.data?.message || error.message));
            setMessageType('error');
        }
    };
    

    return (
        <div className={styles.container}>
            {message && (
                <Message type={messageType} message={message} />
            )}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputGroup}>
                    <label htmlFor="password">Nova Senha</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Digite a nova senha"
                        required
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="confirmPassword">Confirme a Nova Senha</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirme a nova senha"
                        required
                    />
                </div>
                <button type="submit" className={styles.saveButton}>
                    <FaRegSave /> Atualizar Senha
                </button>
            </form>
        </div>
    );
};

export default EditUserPasswordComponent;
