import React from 'react';
import useTenantId from '../../../components/tenantId';
import HorariosPageComponent from '../../../components/pagesComponents/HorariosComponents/HorariosPageComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const HorariosSecretariaPage = () => {
    const tenantId = useTenantId();
    const itemsPagesList = itemsPagesWithExclusions('Horários');

    return (
        <HorariosPageComponent tenantId={tenantId} pagesList={itemsPagesList} accessLevel="highAccess" />
    );
};

export default HorariosSecretariaPage;
