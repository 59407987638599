import axios from 'axios';
import { useSelector } from 'react-redux';

const apiAxios = axios.create({
    //baseURL:"http://localhost:5000",
    baseURL: "https://portal-alternativo-backend-2-0.vercel.app/",
});

const ApiAxiosInterceptor = () => {
    const tokenData = useSelector((state) => state.user?.userInfo?.data?.token);
    const token = useSelector((state) => state.user?.userInfo?.token);

    // Escolhe o primeiro token que não seja nulo ou undefined
    const finalToken = tokenData || token;

    apiAxios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${finalToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return null;
};

export default apiAxios;
export { ApiAxiosInterceptor };