import React from 'react';
import useTenantId from '../../../components/tenantId';
import CardapioComponent from '../../../components/pagesComponents/CardapioComponents/CardapioComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const CardapioSecretariaPage = () => {
  const tenantId = useTenantId();
  const pagesList = itemsPagesWithExclusions('Cardápio');

  return (
    <>
      <CardapioComponent tenantId={tenantId} accessLevel="low" PagesList={pagesList}/>
    </>
  );
}

export default CardapioSecretariaPage;
